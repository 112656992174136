import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
const MainP = tw.span`text-sm `;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);
const Subheading = `text-center md:text-left`;
const Heading = `mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <MainP>
          'Spurnest Techlabs' built the 'VIPSO' app as a Commercial app. This
          SERVICE (APPLICATION) is provided by 'Spurnest Techlabs' and is
          intended for use as is.
        </MainP>
        <br/>
        <MainP>
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
        </MainP>
        <MainP>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </MainP>
        <MainP>
          <h4>
            <strong>Information Collection and Use</strong>
          </h4>
        </MainP>
        <MainP>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information. The
          information that we request will be retained by us and used as
          described in this privacy policy.
        </MainP>
        <MainP>
          The app does not use any third-party services that collect
          information.
        </MainP>
        <MainP>
          Subscriptions purchases are processed by Google Play in a standard
          way. We do not have access to your personal billing information.
        </MainP>
        <MainP>
          <h4>
            <strong>Sensitive permissions required by our applications:</strong>
          </h4>
        </MainP>
        <MainP>
          You are free to allow or prohibit the following permissions to the
          application depending on the features you wish to use:
        </MainP>
        <ul >
          <li>
            android.permission.CAMERA is used for taking pictures of modules and
            profiles;
          </li>
          <li>
            android.permission.READ_CONTACTS is used when you wish to import
            data for Customer or Supplier from your Contacts list;
          </li>
          <li>
            Access to local storage and Google Drive: We do not collect any of
            your data stored in local storage or Google Drive. <br />
            We just provide you the interface to select file to import data
            from. This feature is provided just for your convenience, and you
            can use the application without providing permission to use local
            storage or Google Drive
          </li>
        </ul>
        <MainP>
          <h4>
            <strong>Log Data:</strong>
          </h4>
        </MainP>
        <MainP>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data.
          <br />
          This Log Data may include information such as your device Internet
          Protocol (“IP”) address, device name, operating system version, the
          configuration of the app when utilizing our Service, the time and date
          of your use of the Service, and other statistics.
        </MainP>
        <MainP>
          <h4>
            <strong>Cookies:</strong>
          </h4>
        </MainP>
        <MainP>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
          <br />
          This Service does not use these “cookies” explicitly. However, the app
          may use third party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </MainP>
        <MainP>
          <h4>
            <strong>Service Providers:</strong>
          </h4>
        </MainP>
        <MainP>
          We may employ third-party companies and individuals due to the
          following reasons:
          <br />
          <ul>
            <li>
              To facilitate our Service; To provide the Service on our behalf;
            </li>
            <li>To perform Service-related services;</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </MainP>
        <MainP>
          <h4>
            <strong>Security:</strong>
          </h4>
        </MainP>
        <MainP>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </MainP>
        <MainP>
          <h4>
            <strong>Links to Other Sites:</strong>
          </h4>
        </MainP>
        <MainP>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </MainP>
        <MainP>
          <h4>
            <strong>Children’s Privacy:</strong>
          </h4>
        </MainP>
        <MainP>
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </MainP>
        <MainP>
          <h4>
            <strong>Your Consent:</strong>
          </h4>
        </MainP>
        <MainP>
          By using the Application, you are consenting to our processing of your
          information as set forth in this Privacy Policy now and as amended by
          us. “Processing,” means using or touching information in any way,
          including, but not limited to, collecting, storing, deleting, using,
          combining and disclosing information in ways needed to ensure the
          proper functionality of the Application and as described in this
          Privacy Policy.
        </MainP>
        <MainP>
          <h4>
            <strong>Changes to This Privacy Policy:</strong>
          </h4>
        </MainP>
        <MainP>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. These changes are effective immediately after they are posted on
          this page.
        </MainP>
        <MainP>
          <h4>
            <strong>Contact Us:</strong>
          </h4>
        </MainP>
        <MainP>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at &nbsp;
          <a href="mailto:info@spurnest.com">info@spurnest.com</a>.
        </MainP>
      </Container>

      <Footer />
    </AnimationRevealPage>
  );
};
