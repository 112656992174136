import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import {
  SectionHeading,
  // Subheading as SubheadingBase,
} from "components/misc/Headings.js";

const Container = tw.div`relative`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
// const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
export default ({ heading = "Our Office", subheading = "Locations" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{heading}</Heading>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Card>
            <span className="textContainer">
              <span className="title">{"Gandhinagar" || "Fully Secure"}</span>
              <p className="description">
                <Address>
                  <AddressLine>201, Pratik Mall</AddressLine>
                  <AddressLine>Service Rd, Urjanagar 1, Randesan</AddressLine>
                  <AddressLine>Gandhinagar, Gujarat</AddressLine>
                  <AddressLine>382421</AddressLine>
                </Address>
                <Email>
                  <a href="mailto:info@spurnest.com">info@spurnest.com</a>
                </Email>
                <Phone>
                  <a href="tel:9726816391">+91 97268 16391</a>
                </Phone>
                <Phone>
                  <a href="tel:8460407942">+91 84604 07942</a>
                </Phone>
              </p>
            </span>
          </Card>
          <Card>
            <iframe
              title="google maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.769007948009!2d72.63630551425354!3d23.17862931629556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2b33ff1a1ed7%3A0xff11c903264e2d58!2sSpurnest%20techlabs!5e0!3m2!1sen!2sin!4v1674728166613!5m2!1sen!2sin"
              width="800"
              height="600"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </Card>
        </div>
      </Container>

      {/* <ContactUsForm /> */}

      <Footer />
    </AnimationRevealPage>
  );
};
