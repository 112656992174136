import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Spurnest</Subheading>}
        heading="We are a modern software company."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description={<span>Spurnest Techlabs was started by two passionate blokes: Hardik Suthar and Gautam Prajapati in 2021. Spurnest Techlabs’s headquarters are located in Gandhinagar, India.<br />The founders were committed to building a company with technological expertise to cater to everyone from students to business owners. They also envisioned a workplace that stays up-to-date with the latest trends in technology and is a great environment to work in!</span>} />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to development the design space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        description={"To develop the software based on your needs and vision and provide an better solution for every buissness"}
      />
      <MainFeature1
        subheading={<Subheading>Hiring of Developer</Subheading>}
        heading="You need developer, we have it."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/photo-1573165231977-3f0e27806045?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description={"We have a full team of development from scratch to Live the product. Contact us anytime for hiring of any need of developer you require."}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description=""
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Provides support with professional staff for immediate resolution of customer queries"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "We have strong professional and experienced developers to provide the best software useful to the customer"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "We provide the best software solution to convert customers ideas into a software"
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
