import React, { useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {
  Container,
  Content2Xl,
} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as HandleIcon } from "images/handle-icon.svg";
// import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

import useInView from "@owaiswiz/use-in-view";

/* Hero */
const ComponentsContainer = tw.div`mt-24`;
// const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
const Components = tw.div``;
const Component = tw.div`mt-12 border rounded-lg bg-white`;
// const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
// const ComponentName = tw.h6`text-lg`;
// const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

export default ({
  features = null,
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  // window.gtag("js", new Date());
  // window.gtag("config", "UA-45799926-9");

  const noOfLandingPages = Object.keys(landingPages).length;
  const noOfInnerPages = Object.keys(innerPages).length;
  const noOfComponentBlocks = Object.values(blocks).reduce(
    (acc, block) => acc + Object.keys(block.elements).length,
    0
  );

  features = features || [
    `${noOfLandingPages} Landing Page Demos`,
    `${noOfInnerPages} Inner Pages`,
    `${noOfComponentBlocks} Components`,
    "Uses TailwindCSS",
    "Fully Responsive",
    "Fully Customizable",
  ];

  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-2 -mt-8 pt-8 px-8">
        <Content2Xl>
          {/* <NavRow>
            <LogoLink href="/">
              <img src={logo} alt="" />
              Treact
            </LogoLink>
            <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
              <NavLink target="_blank" href="https://owaiskhan.me/post/free-tailwindcss-react-ui-kit">
                License & Usage
              </NavLink>
              <NavLink target="_blank" href="https://owaiskhan.me">
                Who Am I ?
              </NavLink>
              <NavLink target="_blank" href="https://twitter.com/owaiswiz">
                Twitter
              </NavLink>
              <NavLink target="_blank" href="mailto:owaiswiz@gmail.com">
                Hire Me!
              </NavLink>
              <div tw="md:hidden flex-100 h-0"></div>
              <PrimaryNavLink target="_blank" href="https://gum.co/QaruQ">
                Download Now
              </PrimaryNavLink>
            </div>
          </NavRow>
          <HeroRow>
            <UpdateNotice>
              <UpdateNoticeIcon />
              Last updated on 20th April, 2021 - Added support for React v17 and TailwindCSS v2!
            </UpdateNotice>
            <TextColumn>
              <Heading as="h1">{heading}</Heading>
              <Description>{description}</Description>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList> 
              <Actions>
                <PrimaryButton href={primaryButtonUrl} css={buttonRoundedCss}>
                  {primaryButtonText}
                </PrimaryButton>
                <SecondaryButton href={secondaryButtonUrl}>{secondaryButtonText}</SecondaryButton>
              </Actions>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={heroScreenshotImageSrc} />
              </ImageContainer>
            </ImageColumn>
          </HeroRow>
          <SectionContainer id="landingPageDemos">
            <SectionHeading>Landing Pages</SectionHeading>
            <SectionDescription>
              We have {noOfLandingPages} premade landing pages. Click on the "View Live Demo" button to see them in
              action. Customizing or Creating your own custom landing page is really simple by using our UI components.
            </SectionDescription>
            <PreviewCards>
              {Object.entries(landingPages).map(([pageName, page], index) => (
                <PreviewCardContainer key={index}>
                  <PreviewCard initial="rest" animate="rest" whileHover="hover" href={page.url} target="_blank">
                    <PreviewCardImageContainer>
                      <PreviewCardImage
                        transition={{ type: "tween" }}
                        variants={previewImageAnimationVariants}
                        imageSrc={page.imageSrc}
                      />
                    </PreviewCardImageContainer>
                    <PreviewButton>View Live Demo</PreviewButton>
                  </PreviewCard>
                </PreviewCardContainer>
              ))}
            </PreviewCards>
          </SectionContainer>
          <SectionContainer>
            <SectionHeading>Inner Pages</SectionHeading>
            <SectionDescription>
              We also provide {noOfInnerPages} additional inner pages for your various needs like a signup, login,
              pricing, about us, contact, blog etc. To view them in action click the "View Live Demo" button.
            </SectionDescription>
            <PreviewCards>
              {Object.entries(innerPages).map(([pageName, page], index) => (
                <PreviewCardContainer key={index}>
                  <PreviewCard initial="rest" animate="rest" whileHover="hover" href={page.url} target="_blank">
                    <PreviewCardImageContainer>
                      <PreviewCardImage
                        transition={{ type: "tween" }}
                        variants={!page.scrollAnimationDisabled && previewImageAnimationVariants}
                        imageSrc={page.imageSrc}
                      />
                    </PreviewCardImageContainer>
                    <PreviewButton>View Live Demo</PreviewButton>
                  </PreviewCard>
                </PreviewCardContainer>
              ))}
            </PreviewCards>
          </SectionContainer>

          <SectionContainer id="componentDemos">
            <SectionHeading>Component Blocks</SectionHeading>
            <SectionDescription>
              We also provide {noOfComponentBlocks} components along with the premade landing pages so you can create
              your own landing page within minutes as you see fit. You can combine these components to create 1000s of
              unique attractive web pages.
              <span tw="block text-sm text-gray-500 mt-2">
                (Preview Panel below inspired by Tailwind UI)
              </span>
            </SectionDescription> */}
          <BlocksRenderer blocks={Object.values(blocks)} />
          {/* </SectionContainer> */}
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

const BlocksRenderer = ({ blocks }) => {
  const [lastVisibleBlockIndex, setLastVisibleBlockIndex] = useState(0);

  const updateLastVisibleBlockIndex = (index) => {
    console.log("LAST WAS ", lastVisibleBlockIndex);
    if (index > lastVisibleBlockIndex) setLastVisibleBlockIndex(index);
  };

  return (
    <ComponentsContainer>
      {blocks.map(
        (block, index) =>
          lastVisibleBlockIndex + 1 >= index && (
            <Block
              key={index}
              components={block}
              notifyIsVisible={() => updateLastVisibleBlockIndex(index)}
            />
          )
      )}
    </ComponentsContainer>
  );
};

const Block = ({ notifyIsVisible, components }) => {
  const offset = 30;
  const [ref, inView] = useInView(offset);

  useEffect(() => {
    if (inView) notifyIsVisible();
  }, [inView, notifyIsVisible]);

  const ResizeHandle = (
    <ResizeHandleButton>
      <HandleIcon tw="w-4 h-4 text-gray-600" />
    </ResizeHandleButton>
  );

  const componentBlockRefs = {};

  const updateComponentBlockIframeHeight = (iframe) => {
    iframe.style.height = "auto";
    iframe.style.height =
      iframe.contentWindow.document.body.scrollHeight + "px";
  };

  return (
    <div ref={ref} tw="mt-32">
      {/* <ComponentsType>{components.type}</ComponentsType> */}
      <Components>
        {Object.values(components.elements).map((component, componentIndex) => (
          <Component key={componentIndex}>
            <ComponentContent>
              <ResizableBox
                minWidth={310}
                default={{
                  width: "100%",
                  height: "100%",
                }}
                bounds="parent"
                disableDragging={false}
                enableResizing={{ right: false }}
                resizeHandleComponent={{ right: ResizeHandle }}
                resizeHandleWrapperClass={`resizeHandleWrapper`}
                onResize={() =>
                  updateComponentBlockIframeHeight(
                    componentBlockRefs[component.url]
                  )
                }
              >
                <iframe
                  src={component.url}
                  title="Hero"
                  width="100%"
                  ref={(ref) => (componentBlockRefs[component.url] = ref)}
                  onLoad={(e) => updateComponentBlockIframeHeight(e.target)}
                />
              </ResizableBox>
            </ComponentContent>
          </Component>
        ))}
      </Components>
    </div>
  );
};
